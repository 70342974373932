import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="ml-2 lg:text-lg text-md self-center">
      <button
        className="lg:px-2.5 px-1.5 py-2 border language-middle hover:bg-[#000] dark:hover:bg-white border-[#000] dark:border-white text-[#000] dark:text-white hover:text-white dark:hover:text-[#000]"
        onClick={() => changeLanguage('fr')}
        title="French"
        aria-label="French"
      >
        🇫🇷
      </button>
      <button
        className="lg:px-2.5 px-1.5 py-2 border border-x-0 language-left hover:bg-[#000] dark:hover:bg-white border-[#000] dark:border-white text-[#000] dark:text-white hover:text-white dark:hover:text-[#000]"
        onClick={() => changeLanguage('en')}
        title="English"
        aria-label="English"
      >
        🇬🇧
      </button>
      <button
        className="lg:px-2.5 px-1.5 py-2 border language-right hover:bg-[#000] dark:hover:bg-white border-[#000] dark:border-white text-[#000] dark:text-white hover:text-white dark:hover:text-[#000]"
        onClick={() => changeLanguage('it')}
        title="Italian"
        aria-label="Italian"
      >
        🇮🇹
      </button>
      <button
        className="lg:px-2.5 px-1.5 py-2 border border-l-0 language-middle hover:bg-[#000] dark:hover:bg-white border-[#000] dark:border-white text-[#000] dark:text-white hover:text-white dark:hover:text-[#000]"
        onClick={() => changeLanguage('ru')}
        title="Russian"
        aria-label="Russian"
      >
        🇷🇺
      </button>
    </div>
  );
};

export default LanguageSwitcher;
