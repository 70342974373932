import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import LogoLightFooter from '../assets/images/logo_fat_white.png';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import emailVerifier from '../helpers/emailVerifier';
import {
  MapPin,
  Mail,
  Phone,
  Linkedin,
  Facebook,
  Instagram,
} from 'react-feather';
import { MdKeyboardArrowRight } from '../assets/icons/vander';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  const [status, setStatus] = useState(t('subscribe_button'));
  const form = useRef();
  const sub = useRef()

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current.email.value)

    if(localStorage.getItem('sent') === 'true') {
      form.current.reset();
      return; 
    }

    if(!emailVerifier(form.current.email.value)) {
      setStatus('Invalid Email');
      sub.current.style.backgroundColor = '#ff0000';
      sub.current.disabled = true;
      setTimeout(() => {
        setStatus(t('subscribe_button'));
        sub.current.style.backgroundColor = '#000';
        sub.current.disabled = false;
      }
      , 3000);
      return;
    }

    localStorage.setItem('sent', 'true');
    e.preventDefault();

    console.log(form.current);

    // check ip address, if located in Africa or Pakistan, refuse to send email
    try {
    fetch('https://ipapi.co/json/')
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data.country === 'PK' || data.continent_code === 'AF') {
        setStatus('Invalid Email');
        sub.current.style.backgroundColor = '#ff0000';
        sub.current.disabled = true;
        setTimeout(() => {
          setStatus(t('subscribe_button'));
          sub.current.style.backgroundColor = '#000';
          sub.current.disabled = false;
        }
        , 3000);
        return;
      }
    });
  } catch (error) {
    // do nothing and proceed
  }

    emailjs.sendForm(process.env.REACT_APP_SERVICE_MAIL, process.env.REACT_APP_SERVICE_TEMPLATE_NEWSLETTER, form.current, process.env.REACT_APP_SERVICE_PUBLIC)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      form.current.reset();

      setStatus('✓✓✓✓✓');
      sub.current.style.backgroundColor = '#82ea86';
      sub.current.disabled = true;
      setTimeout(() => {
        setStatus(t('subscribe_button'));
      sub.current.style.backgroundColor = '#000';
      sub.current.disabled = false;
      }, 3000);
  };
  

  return (
    <>
      <footer className="relative bg-[#000] mt-32 dark:border-t-2 dark:border-white">
        <div className="px-10 lg:px-0">
          <div className="lg:container">
            <div className="relative pt-12 pb-10">
              <div className="relative w-full">
                <div className="relative -top-40 bg-white dark:bg-[#000] lg:px-8 px-6 py-10  shadow-lg dark:shadow-gray-700 overflow-hidden">
                  <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                    <div className="ltr:md:text-left rtl:md:text-right text-center z-1">
                      <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-medium text-black dark:text-white">
                        {t('subscribe_to_newsletter')}
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400 max-w-xl mx-auto">
                        {t('subscribe_info')}
                      </p>
                    </div>
                    <div className="subcribe-form z-1">
                      <form className="relative max-w-lg md:ms-auto" ref={form} onSubmit={
                        (e) => sendEmail(e)
                      }>
                        <input
                          type="email"
                          id="subscribe"
                          name="email"
                          className="bg-white dark:bg-[#000] shadow dark:shadow-gray-700"
                          placeholder={t('enter_your_email')}
                        />
                        <button
                          type="submit"
                          className="btn black-n-white"
                          ref={sub}
                        >
                          {status}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="flex justify-around sm:justify-between -mt-24 flex-wrap md:flex-nowrap">
                  <div className="pb-8 md:pb-0 pr-4 min-w-max">
                    <Link2 to="/" className="focus:outline-none">
                      <img height={250} width={250} src={LogoLightFooter} alt="Findr by Interalia Logo" />
                    </Link2>
                  </div>
                  <div className="pb-8 md:pb-0 pr-4 min-w-max">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">
                      {t('company')}
                    </h5>
                    <ul className="list-none footer-list mt-3">
                      <li>
                        <Link2
                          to="/aboutus"
                          className="text-slate-300 hover:text-gray-500 dark:text-gray-400 duration-500 ease-in-out flex items-center"
                        >
                          <MdKeyboardArrowRight className="me-1 text-xl" />{' '}
                          {t('about_us')}
                        </Link2>
                      </li>
                      {/* Same for buy and rent */}
                      <li className="mt-[10px]">
                        <Link2
                          to="/buy"
                          className="text-slate-300 hover:text-gray-500 dark:text-gray-400 duration-500 ease-in-out flex items-center"
                        >
                          <MdKeyboardArrowRight className="me-1 text-xl" />{' '}
                          {t('buy')}
                        </Link2>
                      </li>
                      <li className="mt-[10px]">
                        <Link2
                          to="/rent"
                          className="text-slate-300 hover:text-gray-500 dark:text-gray-400 duration-500 ease-in-out flex items-center"
                        >
                          <MdKeyboardArrowRight className="me-1 text-xl" />{' '}
                          {t('rent')}
                        </Link2>
                      </li>
                    </ul>
                  </div>
                  <div className="pb-8 md:pb-0 pr-4 min-w-max">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">
                      {t('useful_links')}
                    </h5>
                    <ul className="list-none footer-list mt-3">
                      <li>
                        <Link2
                          to="/terms"
                          className="text-slate-300 hover:text-gray-500 dark:text-gray-400 duration-500 ease-in-out flex items-center"
                        >
                          <MdKeyboardArrowRight className="me-1 text-xl" />{' '}
                          {t('terms_of_services')}
                        </Link2>
                      </li>
                      <li className="mt-[10px]">
                        <Link2
                          to="/privacy"
                          className="text-slate-300 hover:text-gray-500 dark:text-gray-400 duration-500 ease-in-out flex items-center"
                        >
                          <MdKeyboardArrowRight className="me-1 text-xl" />{' '}
                          {t('privacy_policy')}
                        </Link2>
                      </li>
                      <li className="mt-[10px]">
                        <Link2
                          to="/contact"
                          className="text-slate-300 hover:text-gray-500 dark:text-gray-400 duration-500 ease-in-out flex items-center"
                        >
                          <MdKeyboardArrowRight className="me-1 text-xl" />{' '}
                          {t('contact')}
                        </Link2>
                      </li>
                    </ul>
                  </div>
                  <div className="pb-8 md:pb-0 pr-4 min-w-max">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">
                      {t('contact')}
                    </h5>
                    <div className="flex mt-3">
                      <MapPin className="w-5 h-5 text-[#f63211] me-3"></MapPin>
                      <div className="">
                        <a
                          className="text-slate-300 hover:text-gray-500 dark:text-gray-400 duration-500 ease-in-out"
                          href="/contact"
                        >
                          31, blvd. des Moulins
                        </a>
                      </div>
                    </div>
                    <div className="flex mt-3">
                      <Mail className="w-5 h-5 text-[#f63211] me-3"></Mail>
                      <div className="">
                        <a
                          href="mailto:hello@findr.mc"
                          className="text-slate-300 hover:text-gray-500 dark:text-gray-400 duration-500 ease-in-out"
                        >
                          hello@findr.mc
                        </a>
                      </div>
                    </div>
                    <div className="flex mt-3">
                      <Phone className="w-5 h-5 text-[#f63211] me-3"></Phone>
                      <div className="">
                        <a
                          href="tel:+377 93 50 78 35"
                          className="text-slate-300 hover:text-gray-500 dark:text-gray-400 duration-500 ease-in-out"
                        >
                          +377 93 50 78 35
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-2 md:mt-6 pt-2 md:pt-12">
                  <p className="mb-0 text-gray-300">
                    © {new Date().getFullYear()} Findr. with{' '}
                    <i className="mdi mdi-heart text-[#f63211]"></i> by{' '}
                    <Link2
                      to="https://www.instudio.digital/"
                      target="_blank"
                      className="text-reset"
                    >
                      inStudio
                    </Link2>
                    .
                  </p>

                  <ul className="list-none ltr:md:text-right rtl:md:text-left text-center -mt-1">
                    <li className="inline ms-1">
                      <Link2
                        to="https://www.linkedin.com/company/findrmc"
                        target="_blank"
                        className="btn btn-icon btn-sm h-[2.25rem] w-[2.25rem] text-[20px] text-white border border-gray-800 dark:border-gray-700  hover:bg-[#f63211] dark:hover:bg-[#f63211]"
                      >
                        <Linkedin className="h-5 w-5"></Linkedin>
                      </Link2>
                    </li>
                    <li className="inline ms-1">
                      <Link2
                        to="https://www.facebook.com/profile.php?id=61554392367829"
                        target="_blank"
                        className="btn btn-icon btn-sm h-[2.25rem] w-[2.25rem] text-[20px] text-white border border-gray-800 dark:border-gray-700  hover:bg-[#f63211] dark:hover:bg-[#f63211]"
                      >
                        <Facebook className="h-5 w-5"></Facebook>
                      </Link2>
                    </li>
                    <li className="inline ms-1">
                      <Link2
                        to="https://instagram.com/findr.mc"
                        target="_blank"
                        className="btn btn-icon btn-sm h-[2.25rem] w-[2.25rem] text-[20px] text-white border border-gray-800 dark:border-gray-700  hover:bg-[#f63211] dark:hover:bg-[#f63211]"
                      >
                        <Instagram className="h-5 w-5"></Instagram>
                      </Link2>
                    </li>
                    <li className="inline ms-1">
                      <Link2
                        to="mailto:hello@findr.mc"
                        className="btn btn-icon btn-sm h-[2.25rem] w-[2.25rem] text-[20px] text-white border border-gray-800 dark:border-gray-700  hover:bg-[#f63211] dark:hover:bg-[#f63211]"
                      >
                        <Mail className="h-5 w-5"></Mail>
                      </Link2>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
