import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './App.css';
import { Analytics } from '@vercel/analytics/react';

import './assets/css/tailwind.output.css';
import './assets/css/custom.css';
import './assets/css/icons.css';
import './assets/translation/i18n.js';

import CookieConsent from 'react-cookie-consent';

import Index from './pages/index.js';
import PropertyDetail from './pages/property-detail';
import Buy from './pages/buy';
import Rent from './pages/Rent';
import Blogs from './pages/blogs.js';
import Aboutus from './pages/aboutus';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import Contact from './pages/contact';
import ScrollToTop from './component/Scroll-top';
import GalleryPage from './pages/Gallery';
import PasswordProtected from './pages/pass';

import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA);

function App() {
  const [passEntered, setPassEntered] = React.useState(false);


  useEffect(() => {
    document.documentElement.setAttribute('dir', 'ltr');
    document.documentElement.classList.add('light');
  }, []);

  const handleCorrectPassword = () => {
    setPassEntered(true);
  };

  // if (!passEntered) {
  //   return <PasswordProtected onCorrectPassword={handleCorrectPassword} />;
  // }

  return (
    <BrowserRouter>
      <Analytics />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/index" element={<Index />} />

        <Route path="/property-detail/:id" element={<PropertyDetail />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/rent" element={<Rent />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/blogs" element={<Blogs />} />

        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />

        {/* <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog-detail" element={<BlogDetail />} /> */}

        <Route path="*" element={<Index />} />
      </Routes>
      <CookieConsent
        style={{ background: '#000', borderTop: '1px solid #fff' }}
        buttonStyle={{
          color: '#000',
          fontSize: '13px',
          background: '#fff',
          borderRadius: '5px',
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </BrowserRouter>
  );
}
export default App;
