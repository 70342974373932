import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as Link2 } from 'react-router-dom';
import ReactSimplyCarousel from 'react-simply-carousel';

const isMobile = window.innerWidth < 768;

export default function GalleryPreview() {
  const { t } = useTranslation();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  
  const images = Array.from({ length: 17 }, (_, i) => `/gallery/JPEG/${i + 1}.jpg`);

  return (
    <>
      <div className="container lg:mt-24 mt-16 text-center">
        <div className="grid grid-cols-1 pb-8">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            {t('ourPreviousWork')}
          </h3>
          <p className="text-gray-500 dark:text-gray-400 max-w-xl mx-auto">
            {t('enjoyPreviousWork')}
          </p>
        </div>
        <div className="mt-8">
          <ReactSimplyCarousel
            activeSlideIndex={activeSlideIndex}
            onRequestChange={setActiveSlideIndex}
            itemsToShow={1}
            itemsToScroll={1}
            speed={400}
            easing="linear"
            autoplay
            autoplayDirection="forward"
            autoplayDelay={3000}
            infinite
            forwardBtnProps={{
              style: {
                alignSelf: 'center',
                background: 'black',
                border: 'none',
                borderRadius: '50%',
                color: 'white',
                cursor: 'pointer',
                fontSize: '20px',
                height: 30,
                lineHeight: 1,
                textAlign: 'center',
                width: 30,
              },
              children: <span>{'>'}</span>,
            }}
            backwardBtnProps={{
              style: {
                alignSelf: 'center',
                background: 'black',
                border: 'none',
                borderRadius: '50%',
                color: 'white',
                cursor: 'pointer',
                fontSize: '20px',
                height: 30,
                lineHeight: 1,
                textAlign: 'center',
                width: 30,
              },
              children: <span>{'<'}</span>,
            }}
            responsiveProps={[
              {
                itemsToShow: 1,
                itemsToScroll: 1,
                minWidth: 0,
              },
              {
                itemsToShow: 2,
                itemsToScroll: 2,
                minWidth: 768,
              },
            ]}
          >
            {images.map((item, index) => (
              <div key={index} style={{ width: isMobile ? 320 : 500, height: 'auto', padding: '1rem' }}>
                <img src={item} alt={`Gallery ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </div>
            ))}
          </ReactSimplyCarousel>
          <div className="mt-4">
            <Link2 to="/gallery" className="btn black-n-white mt-3">
              {t('seeMore')}
            </Link2>
          </div>
        </div>
      </div>
    </>
  );
}
