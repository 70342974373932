import React, { useState } from 'react';
import { Link as Link2 } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import { useTranslation } from 'react-i18next';

import Image from '../assets/images/about.jpeg';
import '../../node_modules/react-modal-video/scss/modal-video.scss';

export default function About({ home = false }) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="container lg:mt-12 mt-16">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
          <div className="md:col-span-5">
            <div className="relative">
              <img src={Image} className=" shadow-md" alt="" />
              <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                {/* <Link2
                  to="#"
                  onClick={() => setOpen(true)}
                  data-type="youtube"
                  data-id="5MX3FsP01b8"
                  className="lightbox h-20 w-20 rounded-full shadow-md dark:shadow-gyay-700 inline-flex items-center justify-center bg-white dark:bg-black text-[#f63211]"
                >
                  <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                </Link2> */}
              </div>
            </div>
          </div>

          <div className="md:col-span-7">
            <div className="lg:ms-4">
              <h4 className="mb-6 finest md:text-3xl text-2xl lg:leading-normal leading-normal font-borna font-semibold capitalize">
              Finest Places. Finest Services.
              </h4>
              <p className={`text-gray-500 dark:text-gray-400 max-w-lg text-left ${home ? "text-lg" : "text-xl"}`}>{t('paragraph1-1')} {t('paragraph1-2')}</p>
              {/* <p className={`text-gray-500 dark:text-gray-400 w-xl text-left ${home ? "text-lg" : "text-xl"}`}>{t('paragraph1-2')}</p> */}
              {
                home &&
              <div className="mt-4">
                <Link2
                  to="/aboutus"
                  className="btn black-n-white  mt-3"
                >
                  {t('learn_more')}
                </Link2>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <ModalVideo
        isOpen={isOpen}
        channel="youtube"
        videoId="5MX3FsP01b8"
        onClose={() => setOpen(false)}
      />
    </>
  );
}
