import React from 'react';
import Navbar from '../component/Navbar';
import Property from '../component/Properties/property';
import Footer from '../component/Footer';
import GetInTouch from '../component/Get-in-touch';
import Search from '../component/Search';
import { PropertyProvider } from '../Context/Propertycontext';

export default function Rent() {
  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section className="relative table w-full py-32 lg:py-36 bg-[url('../../assets/images/bg/day1350.webp')] dark:bg-[url('../../assets/images/bg/night1350.webp')]  bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-[#000] opacity-80"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10 md:mt-0">
            <h3 className="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              Find Your Dream Home
            </h3>
          </div>
        </div>
      </section>

      <section className="relative lg:pt-0 pt-24 md:pb-24 pb-16">
        <PropertyProvider>
          <div className="container relative z-20">
            <Search BaseActiveTabIndex={2} page="rent" />
          </div>
          {/* End Hero  */}
          <Property type={'rental'} />
        </PropertyProvider>
        <GetInTouch />
      </section>
      <Footer />
    </>
  );
}
