import React from 'react';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import ContactImage from '../assets/images/client/04.jpeg';
import { Hexagon } from 'react-feather';
import { BsTelephone, MdMailOutline, FiMapPin } from '../assets/icons/vander';

export default function Contact() {
  const form = useRef();
  const [status, setStatus] = useState('SEND MESSAGE');
  const { t } = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();

    try {
      fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if(data.country === 'PK' || data.continent_code === 'AF') {
          alert('Sorry, we do not accept messages from your country');
          return;
        }
      }
      );
    } catch (error) {
      // do nothing and proceed
    }

    emailjs.sendForm(process.env.REACT_APP_SERVICE_MAIL, process.env.REACT_APP_SERVICE_TEMPLATE, form.current, process.env.REACT_APP_SERVICE_PUBLIC)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      form.current.reset();

      setStatus('SENT ✓');
      setTimeout(() => {
        setStatus('SEND MESSAGE');
      }, 3000);
  };

  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section className="relative table w-full py-32 lg:py-36 bg-no-repeat bg-center bg-cover bg-[url('../../assets/images/bg/day1350.webp')] dark:bg-[url('../../assets/images/bg/night1350.webp')] ">
        <div className="absolute inset-0 bg-[#000] opacity-80"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              {t('contact-us')}
            </h3>
          </div>
        </div>
      </section>
      {/* <!-- Google Map --> */}

      {/* <!-- Start Section--> */}
      <section className="relative lg:py-20 py-16">
        <div className="container lg:mb-24 mb-16">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <Hexagon className="h-32 w-32 fill-green-600/5 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-[#f63211]  transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                  <BsTelephone className="text-3xl" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">{t('phone')}</h5>
                <p className="text-gray-500 dark:text-gray-400 mt-3">
                  {t('phoneUs')}
                </p>

                <div className="mt-5">
                  <a
                    href="tel:+377 93 50 78 35"
                    className="btn btn-link text-[#f63211] hover:text-[#f63211] after:bg-green-600 transition duration-500"
                  >
                    +377 93 50 78 35
                  </a>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <Hexagon className="h-32 w-32 fill-green-600/5 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-[#f63211]  transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                  <MdMailOutline className="text-3xl" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">{t('email')}</h5>
                <p className="text-gray-500 dark:text-gray-400 mt-3">
                  {t('sendEmail')}
                </p>

                <div className="mt-5">
                  <a
                    href="mailto:hello@findr.mc"
                    className="btn btn-link text-[#f63211] hover:text-[#f63211] after:bg-green-600 transition duration-500"
                  >
                    hello@findr.mc
                  </a>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <Hexagon className="h-32 w-32 fill-green-600/5 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-[#f63211]  transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                  <FiMapPin className="text-3xl" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">
                  {t('location')}
                </h5>
                <p className="text-gray-500 dark:text-gray-400 mt-3">
                  31, boulevard des Moulins <br />
                  Monaco, Monte-Carlo, 98000
                </p>

                <div className="mt-5">
                  <a
                    href="/contact"
                    className="text-[#f63211] hover:text-green-700 duration-500 ease-in-out lightbox"
                  >
                    {' '}
                    {t('view_on_google_map')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <img
                src={ContactImage}
                className=" md:w-10/12"
                alt=""
                width="1600"
                height="1600"
              />
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:me-5">
                <div className="bg-white dark:bg-[#000]  shadow dark:shadow-gray-700 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-medium">
                    {t('have_any_questions_get_in_touch')}
                  </h3>

                  <form method="post" name="myForm" id="myForm" ref={form} onSubmit={sendEmail}>
                    <p className="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <label htmlFor="name" className="font-medium">
                          {t('your_name').toUpperCase()}:
                        </label>
                        <input
                          required
                          name="name"
                          id="name"
                          type="text"
                          className="form-input mt-2"
                          placeholder={t('your_name').toUpperCase() + ' :'}
                        />
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <label htmlFor="email" className="font-medium">
                          {t('your_email').toUpperCase()}:
                        </label>
                        <input
                          required
                          name="email"
                          id="email"
                          type="email"
                          className="form-input mt-2"
                          placeholder={t('email').toUpperCase() + ' :'}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <label htmlFor="subject" className="font-medium">
                          {t('your_question').toUpperCase()}:
                        </label>
                        <input
                          required
                          name="subject"
                          id="subject"
                          className="form-input mt-2"
                          placeholder={t('subject').toUpperCase() + ' :'}
                        />
                      </div>

                      <div className="mb-5">
                        <label htmlFor="comments" className="font-medium">
                          {t('your_comment').toUpperCase()}:
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          className="form-input mt-2 textarea"
                          placeholder={t('your_comment').toUpperCase() + ' :'}
                        ></textarea>
                      </div>
                    </div>
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      className={`btn black-n-white ${status === 'SEND MESSAGE' ? '' : 'bg-green-600 hover:bg-green-700'}`}
                    >
                      {`${status ===  'SEND MESSAGE' ? t('send_message').toUpperCase() : t('send_message').toUpperCase() + ' ✓'}`}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Section--> */}

      <Footer />
    </>
  );
}
