import React from 'react'
import { Link as Link2 } from "react-router-dom";
import {BsTelephone} from "../assets/icons/vander"
import { useTranslation } from 'react-i18next';

export default function GetInTouch(){
    const { t } = useTranslation();
    return(
        <div className="container lg:mt-20 mt-12">
            <div className="grid grid-cols-1 text-center">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-medium text-black dark:text-white">{t('have_any_questions_get_in_touch')}</h3>

                <div className="mt-6">
                    <Link2 to="/contact" className="btn black-n-white "><BsTelephone className="align-middle me-2"/>{t('contact_us')}</Link2>
                </div>
            </div>
        </div>
    )
}
