import React from 'react';
import CountUp from 'react-countup';

import Navbar from '../component/Navbar';
import About from '../component/About';
import Footer from '../component/Footer';
import AgentCard from '../component/AgentCard';

import Member1 from '../assets/images/client/profile_placeholder.jpeg';
import Member2 from '../assets/images/client/profile_placeholder.jpeg';
import GetInTouch from '../component/Get-in-touch';
import { useTranslation } from 'react-i18next';

export default function Aboutus() {
  const { t, i18n } = useTranslation();
  console.log(i18n.language);
  const agents = [
    {
      memberImg: Member1,
      name: 'Alex Romano Faraldi',
      role: t('agent'),
      description: t('alex_desc'),
    },
    {
      memberImg: Member2,
      name: 'Jeremy Van den Eynde',
      role: t('agent'),
      description: t('jeremy_desc'),
    },
  ];

  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section className="relative table w-full py-32 lg:py-36 bg-no-repeat bg-center bg-cover bg-[url('../../assets/images/bg/day1350.webp')] dark:bg-[url('../../assets/images/bg/night1350.webp')] ">
        <div className="absolute inset-0 bg-[#000] opacity-80"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              {t('about')}
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape overflow-hidden z-1 text-white dark:text-slate-900"></div>
      </div>
      {/* <!-- End Hero --> */}
      {/* <section className="relative md:pb-14 pb-12 ">
        <About />
      </section> */}
      {/* <!-- Start CTA --> */}

      {/* <!-- End CTA --> */}

      <section className="relative md:pb-16 pb-8">
        <div className="container lg:mt-20 mt-12 md:pb-16 pb-8">
          <div className="grid grid-cols-1 pb-4 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              {t('meetTheAgentTeam')}
            </h3>

            <p className="text-gray-500 dark:text-gray-400 max-w-xl mx-auto">
              Finest Places. Finest Services.
            </p>
          </div>
          <div className="grid md:grid-flow-col mt-8 justify-center gap-8">
            {agents.map((agent, index) => (
              <AgentCard
                key={index}
                memberImg={agent.memberImg}
                name={agent.name}
                role={agent.role}
                socialLinks={agent.socialLinks}
                desc={agent.description}
              />
            ))}
          </div>
        </div>
        <section className="relative py-24 bg-no-repeat bg-center bg-fixed bg-cover bg-[url('../../assets/images/bg/day1350.webp')] dark:bg-[url('../../assets/images/bg/night1350.webp')] ">
          <div className="absolute inset-0 bg-[#000]/60"></div>
          <div className="container">
            <div className="grid lg:grid-cols-12 grid-cols-1 md:text-left text-center justify-center">
              <div className="lg:col-start-2 lg:col-span-10">
                <div className="grid md:grid-cols-3 grid-cols-1 items-center">
                  <div className="counter-box text-center">
                    <span className="text-white lg:text-5xl text-4xl mb-2">
                      <CountUp
                        start={200}
                        className="counter-value"
                        end={500}
                        duration={2.75}
                      />
                      +
                    </span>
                    <h5 className="counter-head text-white text-lg font-medium">
                      {t('propertiesSold')}
                    </h5>
                  </div>

                  <div className="counter-box text-center">
                    <span className="text-white lg:text-5xl text-4xl font-semibold mb-2">
                      <CountUp
                        start={10}
                        className="counter-value"
                        end={50}
                        duration={3}
                      />
                      +
                    </span>
                    <h5 className="counter-head text-white text-lg font-medium">
                      {t('yearsOfExperience')}
                    </h5>
                  </div>

                  <div className="counter-box text-center">
                    <span className="text-white lg:text-5xl text-4xl font-semibold mb-2">
                      <CountUp
                        start={0}
                        className="counter-value"
                        end={4}
                        duration={2.75}
                      />
                      +
                    </span>
                    <h5 className="counter-head text-white text-lg font-medium">
                      {t('agentsAtYourDisposal')}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <GetInTouch />
      </section>
      <Footer />
    </>
  );
}
