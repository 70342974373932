import i18n from '../assets/translation/i18n';
import { useTranslation } from 'react-i18next';

export function formatPriceInfo(property) {
  const { t } = i18n;
  // Check if the property object exists
  if (!property) {
    return 'Property not available';
  }

  // Check for 'priceIsPrivate' flag
  if (property.priceIsPrivate) {
    return t('on_demand');
  }

  // Validate that 'price' and 'servicecharges' are numbers
  const isPriceValid = typeof property.price === 'number';
  const isServiceChargesValid = typeof property.servicecharges === 'number';

  if (property.serviceschargesIncluded) {
    if (isPriceValid && isServiceChargesValid) {
      return (property.price + property.servicecharges).toLocaleString() + ' €';
    }
    return 'Invalid data';
  } else {
    const parts = [];

    if (isPriceValid) {
      parts.push(property.price.toLocaleString() + ' €');
    }

    if (property.type_transaction_code === 'rental') {
      if (isServiceChargesValid) {
        parts.push(property.servicecharges.toLocaleString() + ' €');
      } else {
        parts.push(t('property_fees'));
      }
    }

    if (parts.length === 0) {
      return 'Invalid data';
    }

    return parts.join(' + ');
  }
}

export function formatPriceQuick(price) {
  const { t } = i18n;
  if (price) {
    return parseInt(price).toLocaleString() + ' €';
  }
  return t('on_demand');
}
