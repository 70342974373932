import React, { useEffect } from 'react';
import IndividualProperty from './IndivProperty';
import { useProperty } from '../../Context/Propertycontext'; // Import the hook from your context

export default function Property({ type }) {
  const {
    properties,
    searchProperties,
    setTypeTransactionCode,
    loading,
  } = useProperty();

  useEffect(() => {
    setTypeTransactionCode(type);
    searchProperties(type);
  }, [type]);

  return (
    <>
      <div className="container lg:mt-24 mt-16 min-h-[200px]">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px] mx-2 md:mx-0">
          {!loading ? (
            properties.map((item, index) => (
              <IndividualProperty item={item} index={index} key={item.id} />
            ))
          ) : (
            <div className="text-center">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center w-full">
                Loading
              </h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
