// This File serves as the central point for all API calls to the immotoolbox API

const api = 'https://findr-back.vercel.app/';

//Get all properties from the API
export const getProperties = async () => {
  try {
    const response = await fetch(`${api}properties`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPropertiesFiltered = async (
  keyword,
  minPrice,
  maxPrice,
  propertyType,
  typeTransactionCode
) => {
  console.log(typeTransactionCode, 'typeTransactionCode')
  try {
    const response = await fetch(`${api}propertiesfilter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        keyword: keyword,
        minPrice: minPrice,
        maxPrice: maxPrice,
        propertyType: propertyType,
        typeTransactionCode: typeTransactionCode,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getProperty = async (id) => {
  console.log(id);
  try {
    const response = await fetch(`${api}properties/${id}`);
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getPropertyImages = async (id) => {
  try {
    const response = await fetch(`${api}properties/${id}/images`);
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const passCheck = async (password) => {
  try {
    const response = await fetch(`${api}pass`, {method: 'POST', headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password: password,
    })}
      );
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }
  catch (error) {
    console.log(error);
  }
};
