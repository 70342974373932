import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import { useTranslation } from 'react-i18next';

export default function Privacy() {
  const { t } = useTranslation();

  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section className="relative table w-full py-32 lg:py-36 bg-no-repeat bg-center bg-cover bg-[url('../../assets/images/bg/day1350.webp')] dark:bg-[url('../../assets/images/bg/night1350.webp')]">
        <div className="absolute inset-0 bg-[#000] opacity-80"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              {t('privacy_policy')}
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative lg:py-24 py-16">
        <div className="container">
          <div className="md:flex justify-center">
            <div className="md:w-3/4">
              <div className="p-6 bg-white dark:bg-[#000] shadow dark:shadow-gray-700 ">
                <h1 className="text-2xl font-medium mb-2">
                  Interpretation and Definitions
                </h1>
                <hr className="mb-6" />
                <h2 className="mt-4 text-lg text-black">Interpretation :</h2>
                <p className="text-gray-500 dark:text-gray-400 my-1">
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
                <h2 className="mt-4 text-lg text-black">Definitions :</h2>
                <p className="text-gray-500 dark:text-gray-400 my-1">
                  For the purposes of these Terms and Conditions: <br />
                  <br /> <b className="text-black">Affiliate</b> means an entity
                  that controls, is controlled by or is under common control
                  with a party, where "control" means ownership of 50% or more
                  of the shares, equity interest or other securities entitled to
                  vote for election of directors or other managing authority.{' '}
                  <br />
                  <br /> <b className="text-black">Country</b> refers to: Monaco{' '}
                  <br />
                  <br /> <b className="text-black">Company</b> (referred to as
                  either "the Company", "We", "Us" or "Our" in this Agreement)
                  refers to Interalia S.A.R.L, 31 Bd des Moulins, 98000 Monaco.{' '}
                  <br />
                  <br /> <b className="text-black">Device</b> means any device
                  that can access the Service such as a computer, a cellphone or
                  a digital tablet. <br />
                  <br /> <b className="text-black">Service</b> refers to the
                  Website. <br />
                  <br /> <b className="text-black">Terms and Conditions</b>{' '}
                  (also referred as "Terms") mean these Terms and Conditions
                  that form the entire agreement between You and the Company
                  regarding the use of the Service. This Terms and Conditions
                  agreement has been created with the help of the Terms and
                  Conditions Generator. <br />
                  <br />{' '}
                  <b className="text-black">
                    Third-party Social Media Service{' '}
                  </b>{' '}
                  means any services or content (including data, information,
                  products or services) provided by a third-party that may be
                  displayed, included or made available by the Service. <br />
                  <br /> <b className="text-black">Website</b> refers to Findr.,
                  accessible from https://www.findr.mc <br />
                  <br /> <b className="text-black">You</b> means the individual
                  accessing or using the Service, or the company, or other legal
                  entity on behalf of which such individual is accessing or
                  using the Service, as applicable.
                </p>
                <h1 className="text-2xl font-medium mb-2">
                  Collecting and Using Your Personal Data
                </h1>
                <hr className="mb-6" />
                <h2 className="mt-4 text-lg text-black">Types of Data Collected :</h2>
                <p className="text-gray-500 dark:text-gray-400 my-1">
                  <b className="text-black">Personal Data</b>
                  <br />
                  While using Our Service, We may ask You to provide Us with
                  certain personally identifiable information that can be used
                  to contact or identify You. Personally identifiable
                  information may include, but is not limited to:
                  <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li> Usage Data</li>
                  </ul>
                  <b className="text-black">Usage Data</b>
                  <br />
                  Usage Data is collected automatically when using the Service.
                  <br />
                  <br />
                  Usage Data may include information such as Your Device's
                  Internet Protocol address (e.g. IP address), browser type,
                  browser version, the pages of our Service that You visit, the
                  time and date of Your visit, the time spent on those pages,
                  unique device identifiers and other diagnostic data.
                  <br />
                  <br />
                  When You access the Service by or through a mobile device, We
                  may collect certain information automatically, including, but
                  not limited to, the type of mobile device You use, Your mobile
                  device unique ID, the IP address of Your mobile device, Your
                  mobile operating system, the type of mobile Internet browser
                  You use, unique device identifiers and other diagnostic data.
                  <br />
                  <br />
                  We may also collect information that Your browser sends
                  whenever You visit our Service or when You access the Service
                  by or through a mobile device.
                  <br />
                  <br />
                  <b className="text-black">
                    Tracking Technologies and Cookies
                  </b>
                  <br />
                  We use Cookies and similar tracking technologies to track the
                  activity on Our Service and store certain information.
                  Tracking technologies used are beacons, tags, and scripts to
                  collect and track information and to improve and analyze Our
                  Service. The technologies We use may include:
                  <ul>
                    <li>
                      <b className="text-black">Cookies or Browser Cookies.</b>{' '}
                      A cookie is a small file placed on Your Device. You can
                      instruct Your browser to refuse all Cookies or to indicate
                      when a Cookie is being sent. However, if You do not accept
                      Cookies, You may not be able to use some parts of our
                      Service. Unless you have adjusted Your browser setting so
                      that it will refuse Cookies, our Service may use Cookies.
                    </li>
                    <li>
                      <b className="text-black">Web Beacons.</b> Certain
                      sections of our Service and our emails may contain small
                      electronic files known as web beacons (also referred to as
                      clear gifs, pixel tags, and single-pixel gifs) that permit
                      the Company, for example, to count users who have visited
                      those pages or opened an email and for other related
                      website statistics (for example, recording the popularity
                      of a certain section and verifying system and server
                      integrity).
                    </li>
                  </ul>
                  <br />
                  <br />
                  Cookies can be "Persistent" or "Session" Cookies. Persistent
                  Cookies remain on Your personal computer or mobile device when
                  You go offline, while Session Cookies are deleted as soon as
                  You close Your web browser. Learn more about cookies on the
                  Free Privacy Policy website article.
                  <br />
                  <br />
                  We use both Session and Persistent Cookies for the purposes
                  set out below:
                  <b className="text-black">Necessary / Essential Cookies</b>
                  <br />
                  Type: Session Cookies
                  <br />
                  Administered by: Us
                  <br />
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to
                  use some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services.
                  <br />
                  <br />
                  <b className="text-black">
                    Cookies Policy / Notice Acceptance Cookies
                  </b>
                  <br />
                  Type: Persistent Cookies
                  <br />
                  Administered by: Us
                  <br />
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to
                  use some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services.
                  <br />
                  <br />
                  <b className="text-black">
                    Cookies Policy / Notice Acceptance Cookies
                  </b>
                  <br />
                  Type: Persistent Cookies
                  <br />
                  Administered by: Us
                  <br />
                  Purpose: These Cookies identify if users have accepted the use
                  of cookies on the Website.
                  <br />
                  <br />
                  <b className="text-black">Functionality Cookies</b>
                  <br />
                  Type: Persistent Cookies
                  <br />
                  Administered by: Us
                  <br />
                  Purpose: These Cookies allow us to remember choices You make
                  when You use the Website, such as remembering your login
                  details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid
                  You having to re-enter your preferences every time You use the
                  Website.
                  <br />
                  <br />
                  For more information about the cookies we use and your choices
                  regarding cookies, please visit our Cookies Policy or the
                  Cookies section of our Privacy Policy.
                </p>
                <h2 className="mt-4 text-lg text-black">Use of Your Personal Data :</h2>
                <p className="text-gray-500 dark:text-gray-400 my-1">
                  The Company may use Personal Data for the following purposes:
                  <ul>
                    <li>
                      <b className="text-black">
                        To provide and maintain our Service
                      </b>
                      , including to monitor the usage of our Service.
                    </li>
                    <li>
                      <b className="text-black">To manage Your Account:</b> to
                      manage Your registration as a user of the Service. The
                      Personal Data You provide can give You access to different
                      functionalities of the Service that are available to You
                      as a registered user.
                    </li>
                    <li>
                      <b className="text-black">
                        For the performance of a contract:
                      </b>{' '}
                      the development, compliance and undertaking of the
                      purchase contract for the products, items or services You
                      have purchased or of any other contract with Us through
                      the Service.
                    </li>
                    <li>
                      <b className="text-black">To contact You:</b> To contact
                      You by email, telephone calls, SMS, or other equivalent
                      forms of electronic communication, such as a mobile
                      application's push notifications regarding updates or
                      informative communications related to the functionalities,
                      products or contracted services, including the security
                      updates, when necessary or reasonable for their
                      implementation.
                    </li>
                    <li>
                      <b className="text-black">To provide You</b> with news,
                      special offers and general information about other goods,
                      services and events which we offer that are similar to
                      those that you have already purchased or enquired about
                      unless You have opted not to receive such information.
                    </li>
                    <li>
                      <b className="text-black">To manage Your requests:</b> To
                      attend and manage Your requests to Us.
                    </li>
                    <li>
                      <b className="text-black">For business transfers:</b> We
                      may use Your information to evaluate or conduct a merger,
                      divestiture, restructuring, reorganization, dissolution,
                      or other sale or transfer of some or all of Our assets,
                      whether as a going concern or as part of bankruptcy,
                      liquidation, or similar proceeding, in which Personal Data
                      held by Us about our Service users is among the assets
                      transferred.
                    </li>
                    <li>
                      <b className="text-black">For other purposes</b>: We may
                      use Your information for other purposes, such as data
                      analysis, identifying usage trends, determining the
                      effectiveness of our promotional campaigns and to evaluate
                      and improve our Service, products, services, marketing and
                      your experience.
                    </li>
                  </ul>
                  We may share Your personal information in the following
                  situations:
                  <ul>
                    <li>
                      <b className="text-black">With Service Providers:</b> We
                      may share Your personal information with Service Providers
                      to monitor and analyze the use of our Service, to contact
                      You.
                    </li>
                    <li>
                      <b className="text-black">For business transfers:</b> We
                      may share or transfer Your personal information in
                      connection with, or during negotiations of, any merger,
                      sale of Company assets, financing, or acquisition of all
                      or a portion of Our business to another company.
                    </li>
                    <li>
                      <b className="text-black">With Affiliates:</b> We may
                      share Your information with Our affiliates, in which case
                      we will require those affiliates to honor this Privacy
                      Policy. Affiliates include Our parent company and any
                      other subsidiaries, joint venture partners or other
                      companies that We control or that are under common control
                      with Us.
                    </li>
                    <li>
                      <b className="text-black">With business partners:</b> We
                      may share Your information with Our business partners to
                      offer You certain products, services or promotions.
                    </li>
                    <li>
                      <b className="text-black">With other users:</b> when You
                      share personal information or otherwise interact in the
                      public areas with other users, such information may be
                      viewed by all users and may be publicly distributed
                      outside.
                    </li>
                    <li>
                      <b className="text-black">With Your consent</b>: We may
                      disclose Your personal information for any other purpose
                      with Your consent.
                    </li>
                  </ul>
                  <br />
                  <br />
                  <b className="text-black">Retention of Your Personal Data</b>
                  <br />
                  The Company will retain Your Personal Data only for as long as
                  is necessary for the purposes set out in this Privacy Policy.
                  We will retain and use Your Personal Data to the extent
                  necessary to comply with our legal obligations (for example,
                  if we are required to retain your data to comply with
                  applicable laws), resolve disputes, and enforce our legal
                  agreements and policies.
                  <br />
                  <br />
                  The Company will also retain Usage Data for internal analysis
                  purposes. Usage Data is generally retained for a shorter
                  period of time, except when this data is used to strengthen
                  the security or to improve the functionality of Our Service,
                  or We are legally obligated to retain this data for longer
                  time periods.
                  <br />
                  <br />
                  <b className="text-black">Transfer of Your Personal Data</b>
                  <br />
                  Your information, including Personal Data, is processed at the
                  Company's operating offices and in any other places where the
                  parties involved in the processing are located. It means that
                  this information may be transferred to — and maintained on —
                  computers located outside of Your state, province, country or
                  other governmental jurisdiction where the data protection laws
                  may differ than those from Your jurisdiction.
                  <br />
                  <br />
                  Your consent to this Privacy Policy followed by Your
                  submission of such information represents Your agreement to
                  that transfer.
                  <br />
                  <br />
                  The Company will take all steps reasonably necessary to ensure
                  that Your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of Your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of Your data
                  and other personal information.
                  <br />
                  <br />
                  <b className="text-black">Deleting of Your Personal Data</b>
                  <br />
                  You have the right to delete or request that We assist in
                  deleting the Personal Data that We have collected about You.
                  <br />
                  <br />
                  Our Service may give You the ability to delete certain
                  information about You from within the Service.
                  <br />
                  <br />
                  You may update, amend, or delete Your information at any time
                  by signing in to Your Account, if you have one, and visiting
                  the account settings section that allows you to manage Your
                  personal information. You may also contact Us to request
                  access to, correct, or delete any personal information that
                  You have provided to Us.
                  <br />
                  <br />
                  Please note, however, that We may need to retain certain
                  information when we have a legal obligation or lawful basis to
                  do so.
                  <br />
                  <br />
                  <b className="text-black">Disclosure of Your Personal Data</b>
                  <br />
                  <b className="text-black">Business Transactions</b>
                  <br />
                  If the Company is involved in a merger, acquisition or asset
                  sale, Your Personal Data may be transferred. We will provide
                  notice before Your Personal Data is transferred and becomes
                  subject to a different Privacy Policy.
                  <br />
                  <br />
                  <b className="text-black">Law enforcement</b>
                  <br />
                  Under certain circumstances, the Company may be required to
                  disclose Your Personal Data if required to do so by law or in
                  response to valid requests by public authorities (e.g. a court
                  or a government agency).
                  <br />
                  <br />
                  <b className="text-black">Other legal requirements</b>
                  <br />
                  The Company may disclose Your Personal Data in the good faith
                  belief that such action is necessary to:
                  <ul>
                    <li>Comply with a legal obligation</li>
                    <li>
                      Protect and defend the rights or property of the Company
                    </li>
                    <li>
                      Prevent or investigate possible wrongdoing in connection
                      with the Service
                    </li>
                    <li>
                      Protect the personal safety of Users of the Service or the
                      public
                    </li>
                    <li>Protect against legal liability</li>
                  </ul>
                  <br />
                  <br />
                  <b className="text-black">Security of Your Personal Data</b>
                  <br />
                  The security of Your Personal Data is important to Us, but
                  remember that no method of transmission over the Internet, or
                  method of electronic storage is 100% secure. While We strive
                  to use commercially acceptable means to protect Your Personal
                  Data, We cannot guarantee its absolute security.
                  <br />
                  <br />
                  <h2 className="mt-4 text-lg text-black">Children's Privacy</h2>
                  Our Service does not address anyone under the age of 13. We do
                  not knowingly collect personally identifiable information from
                  anyone under the age of 13. If You are a parent or guardian
                  and You are aware that Your child has provided Us with
                  Personal Data, please contact Us. If We become aware that We
                  have collected Personal Data from anyone under the age of 13
                  without verification of parental consent, We take steps to
                  remove that information from Our servers.
                  <br />
                  <br />
                  If We need to rely on consent as a legal basis for processing
                  Your information and Your country requires consent from a
                  parent, We may require Your parent's consent before We collect
                  and use that information.
                  <br />
                  <br />
                  <b className="text-black"></b>
                  <br />
                  <h2 className="mt-4 text-lg text-black">Links to Other Websites</h2>
                  Our Service may contain links to other websites that are not
                  operated by Us. If You click on a third party link, You will
                  be directed to that third party's site. We strongly advise You
                  to review the Privacy Policy of every site You visit.
                  <br />
                  <br />
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                  <br />
                  <br />
                  <h2 className="text-black mt-4 text-lg">
                    Changes to this Privacy Policy
                  </h2>
                  We may update Our Privacy Policy from time to time. We will
                  notify You of any changes by posting the new Privacy Policy on
                  this page.
                  <br />
                  <br />
                  We will let You know via email and/or a prominent notice on
                  Our Service, prior to the change becoming effective and update
                  the "Last updated" date at the top of this Privacy Policy.
                  <br />
                  <br />
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                  <br />
                  <br />
                  <h2 className="mt-4 text-lg text-black">Contact Us</h2>
                  <p className="text-gray-500 dark:text-gray-400 my-1">
                    If you have any questions about these Terms and Conditions,
                    You can contact us:
                  </p>
                  <ul className="list-disc list-inside text-gray-500 dark:text-gray-400 my-1 ml-6">
                    <li>
                      By email :{' '}
                      <a
                        href="mailto:hello@findr.mc"
                        className="text-[#f63211] hover:text-red-700"
                      >
                        hello@findr.mc
                      </a>
                    </li>
                    <li>
                      By visiting this page on our website :{' '}
                      <Link2
                        to="/contact"
                        className="text-[#f63211] hover:text-red-700"
                      >
                        https://www.findr.mc/contact
                      </Link2>
                    </li>
                    <li>By phone number : +377 93 50 78 35</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Terms & Conditions --> */}

      <Footer />
    </>
  );
}
