export default function AgentCard({ memberImg, name, role, socialLinks, desc }) {
  return (
    <div className="group text-center">
      {/* <div className="relative inline-block md:mx-6 mx-2 md:h-52 h-40 md:w-52 w-40 md:rounded-full  overflow-hidden">
        <img src={memberImg} alt={name} />
      </div> */}

      <div className="content mt-0">
        <span className="text-2xl font-medium">{name}</span>
        {/* <p className="text-gray-500 dark:text-gray-400 underline leading-10">{role}</p> */}
        <p className="text-gray-500 dark:text-gray-400 text-justify mx-4 mt-3">{desc}</p>
      </div>
    </div>
  );
}
