import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import ru from "./ru.json";
import fr from "./fr.json";
import it from "./it.json";

const resources = {
  en: {
    translation: en
  },
  ru: {
    translation: ru
  },
  fr: {
    translation: fr
  },
  it: {
    translation: it
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // default language
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
