import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import Gallery from '../component/Gallery';
import ReactSimplyCarousel from 'react-simply-carousel';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const isMobile = window.innerWidth < 768;

export default function GalleryPage() {
  const { t } = useTranslation();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const images = Array.from({ length: 17 }, (_, i) => `/gallery/JPEG/${i + 1}.jpg`);

  return (
    <>
    <div className="h-full">
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section
        className="relative table w-full py-32 lg:py-36 bg-no-repeat bg-center bg-cover bg-[url('../../assets/images/bg/day1350.webp')] dark:bg-[url('../../assets/images/bg/night1350.webp')] "
      >
        <div className="absolute inset-0 bg-[#000] opacity-80"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              {t('trustedUs')}
            </h3>
          </div>
        </div>
      </section>

        <div className='mx-6 mt-20'>
        <ReactSimplyCarousel
            activeSlideIndex={activeSlideIndex}
            onRequestChange={setActiveSlideIndex}
            itemsToShow={1}
            itemsToScroll={1}
            speed={400}
            easing="linear"
            autoplay
            autoplayDirection="forward"
            autoplayDelay={3000}
            infinite
            forwardBtnProps={{
              style: {
                alignSelf: 'center',
                background: 'black',
                border: 'none',
                borderRadius: '50%',
                color: 'white',
                cursor: 'pointer',
                fontSize: '20px',
                height: 30,
                lineHeight: 1,
                textAlign: 'center',
                width: 30,
              },
              children: <span>{'>'}</span>,
            }}
            backwardBtnProps={{
              style: {
                alignSelf: 'center',
                background: 'black',
                border: 'none',
                borderRadius: '50%',
                color: 'white',
                cursor: 'pointer',
                fontSize: '20px',
                height: 30,
                lineHeight: 1,
                textAlign: 'center',
                width: 30,
              },
              children: <span>{'<'}</span>,
            }}
            responsiveProps={[
              {
                itemsToShow: 1,
                itemsToScroll: 1,
                minWidth: 0,
              },
              {
                itemsToShow: 2,
                itemsToScroll: 2,
                minWidth: 768,
              },
            ]}
          >
            {images.map((item, index) => (
              <div key={index} style={{ width: isMobile ? 320 : 500, height: 'auto', padding: '1rem' }}>
                <img src={item} alt={`Gallery ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </div>
            ))}
          </ReactSimplyCarousel>
        </div>
      </div>
      <Footer />
    </>
  );
}
