import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import LogoLight from '../assets/images/logo_fat_white.png';
import LogoDark from '../assets/images/logo_fat.png';
import LanguageSwitcher from './LanguageSwitch';
import Switcher from './Switcher';

export default function Navbar(props) {
  const { t } = useTranslation();

  const { navClass, topnavClass } = props;
  const [isOpen, setMenu] = useState(true);
  window.addEventListener('scroll', windowScroll);

  const toggleMenu = () => {
    setMenu(!isOpen);
    if (document.getElementById('navigation')) {
      var elements = document
        .getElementById('navigation')
        .getElementsByTagName('a');
      for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].onclick = function (elem) {
          if (elem.target.getAttribute('href') !== '') {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle('open');
            }
          }
        };
      }
    }
  };
  function windowScroll() {
    const navbar = document.getElementById('topnav');
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      if (navbar !== null) {
        navbar?.classList.add('nav-sticky');
      }
    } else {
      if (navbar !== null) {
        navbar?.classList.remove('nav-sticky');
      }
    }

    const mybutton = document.getElementById('back-to-top');
    if (mybutton != null) {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        mybutton.classList.add('flex');
        mybutton.classList.remove('hidden');
      } else {
        mybutton.classList.add('hidden');
        mybutton.classList.remove('flex');
      }
    }
  }

  const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };

  useEffect(() => {
    var menuItems = document.getElementsByClassName('sub-menu-item');
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');

        var immediateParent = getClosest(matchingMenuItem, 'li');

        if (immediateParent) {
          immediateParent.classList.add('active');
        }

        var parent = getClosest(immediateParent, '.child-menu-item');
        if (parent) {
          parent.classList.add('active');
        }

        var parent = getClosest(parent || immediateParent, '.parent-menu-item');

        if (parent) {
          parent.classList.add('active');

          var parentMenuitem = parent.querySelector('.menu-item');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }

          var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            '.parent-parent-menu-item'
          );
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
    }

    var elements = document
      .getElementById('navigation')
      .getElementsByTagName('a');
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].onclick = function (elem) {
        if (elem.target.getAttribute('href') === '#') {
          var submenu = elem.target.nextElementSibling.nextElementSibling;
          submenu.classList.toggle('open');
        }
      };
    }
  }, []);

  // render() {
  // const toggleClass = this.state.isOpenMenu ? 'hidden' : 'block';

  return (
    <React.Fragment>
      <nav
        id="topnav"
        className={`defaultscroll is-sticky ${topnavClass ? topnavClass : ''}`}
      >
        <div
          className={`${ 'container'
          //   // topnavClass !== '' && topnavClass !== undefined
          //   //   ? 'container-fluid px-3'
          //   //   : 'container'
          }`}
        >
          {/* <!-- Logo container--> */}
          {navClass === '' || navClass === undefined ? (
            <Link className="logo pt-1" to="/">
              <img
                src={LogoDark}
                className="inline-block dark:hidden"
                alt=""
                height="24"
                width="86"
              />
              <img
                src={LogoLight}
                className="hidden dark:inline-block"
                alt="Findr. by Interalia Logo"
                height="24"
                width="86"
              />
            </Link>
          ) : (
            <Link className="logo" to="/">
              <span className="inline-block dark:hidden">
                <img
                  src={LogoDark}
                  className="l-dark"
                  height="24"
                  width="86"
                  alt="Findr. by Interalia Logo"
                />
                <img
                  src={LogoLight}
                  className="l-light"
                  height="24"
                  width="86"
                  alt=""
                />
              </span>
              <img
                src={LogoLight}
                height="24"
                width="86"
                className="hidden dark:inline-block"
                alt=""
              />
            </Link>
          )}
          {/* <!-- End Logo container--> */}

          {/* <!-- Start Mobile Toggle --> */}
          <div className="menu-extras">
            <div className="menu-item">
              <Link
                to="#"
                className="navbar-toggle"
                id="isToggle"
                onClick={toggleMenu}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>
          {/* <!-- End Mobile Toggle --> */}

          {/* <!-- Login button Start --> */}
          <ul className="buy-button list-none mb-0 flex flex-row items-center mt-[1.1rem] lg:mt-3.5">
            <li className="sm:inline ps-1 mb-0">
              <Switcher />
            </li>
            <li className="sm:inline ps-1 mb-0">
              <LanguageSwitcher />
            </li>
          </ul>
          {/* <!--Login button End--> */}

          <div
            id="navigation"
            className={`${isOpen === true ? 'hidden' : 'block'}`}
          >
            {/* <!-- Navigation Menu--> */}
            <ul
              className={`navigation-menu  ${
                navClass === '' || navClass === undefined ? '' : 'nav-light'
              }   ${
                topnavClass !== '' && topnavClass !== undefined
                  ? 'justify-center'
                  : 'justify-end'
              }`}
            >
              <li>
                <NavLink
                  to="/"
                  activeclassname="active"
                  className="sub-menu-item"
                >
                  {t('home')}
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/buy"
                  activeclassname="active"
                  className="sub-menu-item"
                  onClick={() => {
                    sessionStorage.setItem('minPrice', 0);
                    sessionStorage.setItem('maxPrice', 90000000000);
                  }}
                >
                  {t('buy')}
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/rent"
                  activeclassname="active"
                  className="sub-menu-item"
                  onClick={() => {
                    sessionStorage.setItem('minPrice', 0);
                    sessionStorage.setItem('maxPrice', 90000000000);
                  }}
                >
                  {t('rent')}
                </NavLink>
              </li>

              <li className="has-submenu parent-parent-menu-item">
                <Link to="/gallery" className="sub-menu-item">
                  {t('gallery')}
                </Link>
              </li>

              <li className="has-submenu parent-parent-menu-item">
                <Link to="/aboutus" className="sub-menu-item">
                  {t('about')}
                </Link>
              </li>

              <li className="has-submenu parent-parent-menu-item">
                <Link to="/blogs" className="sub-menu-item">
                  Blog
                </Link>
              </li>

              <li>
                <Link to="/contact" className="sub-menu-item">
                  {t('contact')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* End Navbar  */}
    </React.Fragment>
  );
}
