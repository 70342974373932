import React, { createContext, useContext, useState } from 'react';
import { getPropertiesFiltered } from '../api/immotoolbox';
import { useEffect } from 'react';

const PropertyContext = createContext();

export const useProperty = () => {
  return useContext(PropertyContext);
};

export const PropertyProvider = ({ children }) => {
  const initialMinPrice = sessionStorage.getItem('minPrice') || 0;
  const initialMaxPrice = sessionStorage.getItem('maxPrice') || 900000000;

  const [minPrice, setMinPrice] = useState(initialMinPrice);
  const [maxPrice, setMaxPrice] = useState(initialMaxPrice);

  useEffect(() => {
    sessionStorage.setItem('minPrice', minPrice);
  }, [minPrice]);

  useEffect(() => {
    sessionStorage.setItem('maxPrice', maxPrice);
  }, [maxPrice]);
  
  const [properties, setProperties] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [typeTransactionCode, setTypeTransactionCode] = useState('');
  const [loading, setLoading] = useState(false);

  const searchProperties = (type = '') => {
    setLoading(true);
    console.log('useProperty', typeTransactionCode)
    const fetchData = async () => {
      const data = await getPropertiesFiltered(
        keyword,
        minPrice,
        maxPrice,
        propertyType,
        type || typeTransactionCode
      );
      setProperties(data);
    };
    
    fetchData();
    setLoading(false);
  };

  const value = {
    keyword,
    setKeyword,
    minPrice,
    maxPrice,
    propertyType,
    setMinPrice,
    setMaxPrice,
    setPropertyType,
    searchProperties,
    typeTransactionCode,
    setTypeTransactionCode,
    properties,
    setProperties,
    loading
  };

  return (
    <PropertyContext.Provider value={value}>
      {children}
    </PropertyContext.Provider>
  );
};
