import React from 'react';
import { BsSun, BsMoon } from '../assets/icons/vander';

export default function Switcher() {
  const changeMode = () => {
    if (document.documentElement.className.includes('dark')) {
      document.documentElement.className = 'light';
    } else {
      document.documentElement.className = 'dark';
    }
  };

  return (
    <>
      {/* <!-- Switcher --> */}
      <div className="scale-[80%] lg:scale-100">
        <span className="relative inline-block">
          <input
            type="checkbox"
            className="checkbox opacity-0 absolute"
            id="chk"
            onClick={() => changeMode()}
          />
          <label
            className="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-700 cursor-pointer rounded-full flex justify-between items-center p-2 w-14 h-8"
            htmlFor="chk"
          >
            <BsMoon className="text-yellow-500" />
            <BsSun className="text-yellow-500" />
            <span className="ball bg-white dark:bg-[#000] rounded-full absolute top-[2px] start-[2px] w-7 h-7"></span>
          </label>
        </span>
      </div>

      {/* <!-- Switcher --> */}
    </>
  );
}
