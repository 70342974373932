import { useEffect, useState } from 'react';
import { getProperties } from '../../api/immotoolbox';
import { useTranslation } from 'react-i18next';
import IndividualProperty from './IndivProperty';

export default function PropertyFeatured() {
  const { t } = useTranslation();
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const limit = isMobile ? 4 : 3;
  const [propertiesFeatured, setPropertiesFeatures] = useState([]);

  useEffect(() => {
    getProperties().then((data) => {
        const sortedAndLimitedData = data
          .sort((a, b) => (b.price - a.price))
          .slice(0, limit);
        
          setPropertiesFeatures(sortedAndLimitedData);
    });
  }, []);

  return (
    <>
      <div className="container lg:mt-24 mt-16">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            {t('featured_properties')}
          </h3>
          <p className="text-gray-500 dark:text-gray-400 max-w-xl mx-auto">
            {t('find_the_featured_properties_listed_by_Findr.')}
          </p>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 mt-8 gap-[30px]">
          {propertiesFeatured
            ? propertiesFeatured.map((item, index) => (
                <IndividualProperty item={item} index={index} key={item.id} />
              ))
            : 'Loading...'}
        </div>
      </div>
    </>
  );
}
