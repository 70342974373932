import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import About from '../component/About';
import PropertyFeatured from '../component/Properties/propertyfeatured';
import GetInTouch from '../component/Get-in-touch';
import { PropertyProvider } from '../Context/Propertycontext';
import Search from '../component/Search';
import GalleryPreview from '../component/GalleryPreview';

// import agent from '../assets/images/bg/agent.jpg';
// import appt from '../assets/images/bg/appt.jpeg';
// import backtop from '../assets/images/bg/backtop.jpg';
import backTopVideo from '../assets/ShortFinal3c.mp4';
import LogoFull from '../assets/images/logo_full.svg';

export default function Index() {

  return (
    <>
      <Navbar />
      {/* Hero Start  */}
      <section className="relative mt-20 mb-24 md:mb-10">
        <div className="container-fluid">
          <div
            className="relative pt-28 pb-40 table w-full shadow-md overflow-hidden"
            id="home"
          >
            <div className="absolute inset-0 bg-[#000]/10 z-2"></div>
            {/* <img
              className="absolute inset-0 w-full h-full object-cover z-1"
              src={backtop}
              alt="Background"
              /> */}
              <video preload="true" autoPlay loop muted playsInline className="absolute inset-0 w-full h-full object-cover z-1">
                <source src={backTopVideo} type="video/mp4" />
              </video>
            {/* <div className="flex md:flex-row-reverse flex-col absolute inset-0">
              <div className="flex-1 relative">
                <img
                  className="absolute inset-0 w-full h-full object-cover"
                  src={agent}
                  alt="Agent"
                />
              </div>
              <div className="flex-1 relative">
                <img
                  className="absolute inset-0 w-full h-full object-cover"
                  src={appt}
                  alt="Appartment"
                />
              </div> */}
            {/* </div> */}

            <div className="container z-10">
              <div className="grid grid-cols-1">
                <div className="text-center">
                  <img
                  src={LogoFull}
                  alt='Full sized logo'
                  className='md:w-1/3 w-1/2 mx-auto'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hero End */}

      <section className="relative md:pb-24 pb-16 z-10">
        <div className="container">
          <PropertyProvider>
            <Search home={true} />
          </PropertyProvider>
        </div>

        <About home={true} />
        <PropertyFeatured />
        <GalleryPreview />
        <GetInTouch />
      </section>
      <Footer />

      {/* <!-- End --> */}
    </>
  );
}
