import { useTranslation } from 'react-i18next';
import { useProperty } from '../Context/Propertycontext';

export default function Slider({
  setActiveIndex,
  activeTabIndex,
  center = false,
}) {
  const { t } = useTranslation();
  const { 
    setKeyword,
    setPropertyType,
  } = useProperty();
  const handleTabClick = (tabIndex) => {
    sessionStorage.setItem('minPrice', 0);
    sessionStorage.setItem('maxPrice', 90000000000);
    setKeyword('');
    setPropertyType('');
    setActiveIndex(tabIndex);
  };

  return (
    <ul
      className={`inline-block ${
        center ? 'mx-auto' : ''
      } sm:w-fit w-full flex-wrap justify-center text-center p-4 bg-white dark:bg-[#000] shadow dark:shadow-gray-700`}
      id="myTab"
      data-tabs-toggle="#StarterContent"
      role="tablist"
    >
      <li role="presentation" className="inline-block">
        <button
          onClick={() => handleTabClick(0)}
          className={`px-6 py-2 text-base font-medium  w-full transition-all duration-500 ease-in-out ${
            activeTabIndex === 0
              ? 'text-white dark:text-[#000] bg-[#000] dark:bg-white'
              : 'hover:text-[#f63211]'
          }`}
          id="buy-home-tab"
          data-tabs-target="#buy-home"
          type="button"
          role="tab"
          aria-controls="buy-home"
          aria-selected={activeTabIndex === 0 ? 'true' : 'false'}
        >
          {t('buy').toUpperCase()}
        </button>
      </li>
      <li role="presentation" className="inline-block">
        <button
          onClick={() => handleTabClick(1)}
          className={`px-6 py-2 text-base font-medium  w-full transition-all duration-500 ease-in-out ${
            activeTabIndex === 1
              ? 'text-white dark:text-[#000] bg-[#000] dark:bg-white'
              : 'hover:text-[#f63211]'
          }`}
          id="sell-home-tab"
          data-tabs-target="#sell-home"
          type="button"
          role="tab"
          aria-controls="sell-home"
          aria-selected={activeTabIndex === 1 ? 'true' : 'false'}
        >
          {t('sell').toUpperCase()}
        </button>
      </li>
      <li role="presentation" className="inline-block">
        <button
          onClick={() => handleTabClick(2)}
          className={`px-6 py-2 text-base font-medium  w-full transition-all duration-500 ease-in-out ${
            activeTabIndex === 2
              ? 'text-white dark:text-[#000] bg-[#000] dark:bg-white'
              : 'hover:text-[#f63211]'
          }`}
          id="rent-home-tab"
          data-tabs-target="#rent-home"
          type="button"
          role="tab"
          aria-controls="rent-home"
          aria-selected={activeTabIndex === 2 ? 'true' : 'false'}
        >
          {t('rent').toUpperCase()}
        </button>
      </li>
    </ul>
  );
}
