import Slider from '../component/Slider';
import Select from 'react-select';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as Link2 } from 'react-router-dom';
import { BsTelephone } from '../assets/icons/vander';
import { useProperty } from '../Context/Propertycontext';
import { useNavigate } from 'react-router-dom';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
  }),
};

export default function Search({
  BaseActiveTabIndex = 0,
  home = false,
  page = '',
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const Houses = [
    { value: '1', label: t('studio') },
    { value: '2', label: `2 ${t('rooms')}` },
    { value: '3', label: `3 ${t('rooms')}` },
    { value: '4', label: `4 ${t('rooms')}` },
    { value: '5', label: `5+ ${t('rooms')}` },
    { value: 'Bureau', label: t('offices') },
    { value: 'Parking', label: t('parking') },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // check if mobile
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  const [activeTabIndex, setactiveTabIndex] = useState(BaseActiveTabIndex);
  const {
    keyword,
    setKeyword,
    searchProperties,
    minPrice,
    maxPrice,
    propertyType,
    setMinPrice,
    setMaxPrice,
    setPropertyType,
    typeTransactionCode,
    setTypeTransactionCode,
  } = useProperty();

  useEffect(() => {
    if (activeTabIndex === 0) {
      setTypeTransactionCode('sale');
    } else if (activeTabIndex === 2) {
      setTypeTransactionCode('rental');
    }
  }, [activeTabIndex]);

  const handleSearch = (e) => {
    console.log(keyword, minPrice, maxPrice, propertyType, typeTransactionCode);
    e.preventDefault();
    searchProperties();
    if (home) {
      navigate(activeTabIndex === 0 ? '/buy' : '/rent');
    }
  };

  // const handleReset = (e) => {
  //   e.preventDefault();
  //   setKeyword('');
  //   setMinPrice(0);
  //   setMaxPrice(9999999999999);
  //   setPropertyType(null);
  //   searchProperties(
  //     keyword || null,
  //     propertyType || null,
  //     minPrice || null,
  //     maxPrice || null,
  //     typeTransactionCode || null
  //   );
  // }

  return (
    <div className="grid grid-cols-1 justify-center text-[15px]">
      <div className="relative -mt-44 md:-mt-12">
        <div className="grid grid-cols-1">
          {page === '' && (
            <Slider
              setActiveIndex={setactiveTabIndex}
              activeTabIndex={activeTabIndex}
            />
          )}
          <div
            id="StarterContent"
            className="p-4 bg-white dark:bg-[#000] rounded-ss-none  shadow-md dark:shadow-gray-700"
          >
            {activeTabIndex === 0 && (
              <div id="buy-home" role="tabpanel" aria-labelledby="buy-home-tab">
                <form>
                  <div className="registration-form text-dark dark:text-dark text-start">
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 lg:gap-0 gap-4 md:gap-6">
                      <div className="col-span-2 md:col-span-1">
                        <label className="form-label text-slate-900 dark:text-white font-medium">
                          {t('search')} :{' '}
                        </label>
                        <div className="filter-search-form relative filter-border mt-2 flex items-center justify-center">
                          <input
                            name="name"
                            type="text"
                            id="job-keyword"
                            className=" mt-2 w-11/12 h-fit form-input filter-input-box bg-gray-50 dark:border dark:border-gray-800 border-0"
                            placeholder={t('searchKeywords')}
                            onChange={(e) => {
                              console.log(e.target.value, 'keyword search!!');
                              setKeyword(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-span-2 md:col-span-1">
                        <label
                          htmlFor="buy-properties"
                          className="form-label text-slate-900 dark:text-white font-medium"
                        >
                          {t('select_categories')} :
                        </label>
                        <div className="filter-search-form relative filter-border mt-2 curso">
                          <Select
                            styles={customStyles}
                            className="form-input filter-input-box bg-gray-50 dark:bg-[#000] border-0"
                            options={Houses}
                            onChange={(e) => {
                              console.log(e.value);
                              setPropertyType(e.value);
                            }}
                            isSearchable={false}
                            placeholder={t('select')}
                          />
                        </div>
                      </div>

                      <div className="col-span-1">
                        <label
                          htmlFor="buy-min-price"
                          className="form-label text-slate-900 dark:text-white font-medium"
                        >
                          {isMobile ? t('min_price') : t('minimum_price')} :
                        </label>
                        <div className="filter-search-form relative filter-border mt-2 flex items-center justify-center">
                          <input
                            styles={customStyles}
                            className="  mt-2 w-11/12 h-fit form-input filter-input-box bg-gray-50 dark:border dark:border-gray-800 border-0"
                            onChange={(e) => setMinPrice(e.target.value)}
                            placeholder={t('select')}
                          />
                        </div>
                      </div>

                      <div className="col-span-1">
                        <label
                          htmlFor="buy-max-price"
                          className="form-label text-slate-900 dark:text-white font-medium"
                        >
                          {isMobile ? t('max_price') : t('maximum_price')} :
                        </label>
                        <div className="filter-search-form relative filter-border mt-2 flex items-center justify-center">
                          <input
                            styles={customStyles}
                            className="  mt-2 w-11/12 h-fit form-input filter-input-box bg-gray-50 dark:border dark:border-gray-800 border-0"
                            onChange={(e) => setMaxPrice(e.target.value)}
                            placeholder={t('select')}
                          />
                        </div>
                      </div>

                      <div className="lg:mt-6 col-span-2  md:col-span-1">
                        <input
                          type="submit"
                          id="search-buy"
                          name="search"
                          className="btn black-n-white searchbtn submit-btn w-full !h-12 cursor-pointer"
                          value={t('search').toUpperCase()}
                          onClick={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {activeTabIndex === 1 && (
              <div
                id="sell-home"
                role="tabpanel"
                aria-labelledby="sell-home-tab"
                className='flex flex-col justify-center items-center'
              >
                  <span className="mt-6 mx-auto w-3/4 text-center">
                    {t('salesText')}
                  </span>
                <div className="my-6 mx-auto w-1/2">
                  <Link2
                    to="/contact"
                    className="btn black-n-white  flex justify-center"
                  >
                    <BsTelephone className="align-middle me-2" />
                    {t('contact_us')}
                  </Link2>
                </div>
              </div>
            )}
            {activeTabIndex === 2 && (
              <div
                id="rent-home"
                role="tabpanel"
                aria-labelledby="rent-home-tab"
              >
                <form>
                  <div className="registration-form text-dark text-start">
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 lg:gap-0 gap-4 md:gap-6">
                      <div className="col-span-2 md:col-span-1">
                        <label className="form-label text-slate-900 dark:text-white font-medium">
                          {t('search')} :{' '}
                        </label>
                        <div className="filter-search-form relative filter-border mt-2 flex items-center justify-center">
                          <input
                            name="name"
                            type="text"
                            id="job-keyword"
                            className="  mt-2 w-11/12 h-fit form-input filter-input-box bg-gray-50 dark:border dark:border-gray-800 border-0"
                            placeholder={t('searchKeywords')}
                            onChange={(e) => {
                              console.log(e.target.value, 'keyword search!!');
                              setKeyword(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-span-2 md:col-span-1">
                        <label
                          htmlFor="buy-properties"
                          className="form-label text-slate-900 dark:text-white font-medium"
                        >
                          {t('select_categories')} :
                        </label>
                        <div className="filter-search-form relative filter-border mt-2">
                          <Select
                            styles={customStyles}
                            className="form-input filter-input-box bg-gray-50 dark:bg-[#000] border-0"
                            options={Houses}
                            onChange={(e) => setPropertyType(e.value)}
                            isSearchable={false}
                            placeholder={t('select')}
                          />
                        </div>
                      </div>

                      <div className="col-span-1">
                        <label
                          htmlFor="buy-min-price"
                          className="form-label text-slate-900 dark:text-white font-medium"
                        >
                          {isMobile ? t('min_price') : t('minimum_price')} :
                        </label>
                        <div className="filter-search-form relative filter-border mt-2 flex items-center justify-center">
                          <input
                            styles={customStyles}
                            className="  mt-2 w-11/12 h-fit form-input filter-input-box bg-gray-50 dark:border dark:border-gray-800 border-0"
                            onChange={(e) => setMinPrice(e.target.value)}
                            placeholder={t('select')}
                          />
                        </div>
                      </div>

                      <div className="col-span-1">
                        <label
                          htmlFor="buy-max-price"
                          className="form-label text-slate-900 dark:text-white font-medium"
                        >
                          {isMobile ? t('max_price') : t('maximum_price')} :
                        </label>
                        <div className="filter-search-form relative filter-border mt-2 flex items-center justify-center">
                          <input
                            styles={customStyles}
                            className="  mt-2 w-11/12 h-fit form-input filter-input-box bg-gray-50 dark:border dark:border-gray-800 border-0"
                            onChange={(e) => setMaxPrice(e.target.value)}
                            placeholder={t('select')}
                          />
                        </div>
                      </div>

                      <div className="lg:mt-6 col-span-2 md:col-span-1">
                        <input
                          type="submit"
                          id="search-buy"
                          name="search"
                          className="btn black-n-white searchbtn submit-btn w-full !h-12 cursor-pointer"
                          value={t('search').toUpperCase()}
                          onClick={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
