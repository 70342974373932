import {
  LiaCompressArrowsAltSolid,
  LiaBathSolid,
  LuBedSingle
} from '../../assets/icons/vander';
import { Link } from 'react-router-dom';
import { formatPriceInfo } from '../../helpers/Priceformat';
import { useTranslation } from 'react-i18next';
import parking from '../../assets/images/parking.jpg';

export default function IndividualProperty({ item, index }) {
  const { t } = useTranslation();
  return (
    <Link
      to={`/property-detail/${item.id}`}
      className="text-lg font-medium ease-in-out"
    >
      <div
        className="group  bg-white dark:bg-[#000] shadow hover:shadow-2xl dark:hover:shadow-xl dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 h-full dark:border-2 dark:border-gray-800"
        key={index}
      >
        <div className="relative h-44 md:h-60">
          {item.type_property === 'Parking' && !item.large ? (
            <img
              src={parking}
              alt="parking"
              className="absolute inset-0 object-cover w-full h-full"
            />
          ) : (
            <div
              className="absolute inset-0 bg-center bg-cover"
              style={{ backgroundImage: `url(${item.large})` }}
            ></div>
          )}
        </div>

        <div className="p-4 md:p-6">
          <div className="pb-2 md:pb-6 text-xl text-center md:text-start">
            <h3>{item.reference}</h3>
          </div>
          {item.type_property === 'Parking' ? (
            <div className="text-xl md:py-6 py-2 border-y border-slate-100 dark:border-gray-800 flex items-center list-none">
              <span>{item.district_name}</span>
            </div>
          ) : (
            <ul className="md:py-6 py-2 border-y border-slate-100 dark:border-gray-800 flex flex-col md:flex-row items-center list-none">
              <li className="flex items-center me-4">
                <LiaCompressArrowsAltSolid className="text-xl me-2 text-[#f63211]" />
                <i></i>
                <span className='text-[15px]'>{parseInt(item.total_area).toFixed()}m2</span>
              </li>

              <li className="flex items-center me-4">
                <LuBedSingle className="text-xl me-2 text-[#f63211]" />
                <span className='text-[15px]'>
                  {item.num_bedrooms <= 0
                    ? t('studio')
                    : item.num_bedrooms == 1 ? item.num_bedrooms + ' ' + t('beds').slice(0, -1) :
                    item.num_bedrooms + ' ' + t('beds')}
                </span>
              </li>

              {/* Show this only in md or + */}

              <li className="hidden items-center xl:flex">
                <LiaBathSolid className="text-xl me-2 text-[#f63211]" />
                <span className='text-[15px]'>{item.num_bathrooms + ' ' + t('bathrooms')}</span>
              </li>
            </ul>
          )}

          <ul className="md:pt-6 pt-3 flex md:flex-row flex-col justify-between items-center list-none">
            <li>
              <span className="text-gray-500 dark:text-gray-400">{t('price')}</span>
              <p className="text-lg font-medium">{formatPriceInfo(item)}
              {/* If transaction type is rental, add gray /months */}
              {item.type_transaction_code === 'rental' && (
                <span className="text-gray-500 dark:text-gray-400">
                {' '}/ {t('month')}
                </span>
              )}
              </p>
            </li>
            <li className="flex md:flex-col flex-nowrap justify-between items-center whitespace-nowrap">
              {/* Parking */}
              <li>
                {item.num_parkings > 0 && (
                  <span className="bg-green-600/10 text-green-600 text-sm px-2.5 py-0.75 h-6">
                    {t('parking')}
                  </span>
                )}
              </li>
              {/* Cellar */}
              <li>
                {item.num_cellars > 0 && (
                  <span className="bg-green-600/10 text-green-600 text-sm px-2.5 py-0.75 h-6 hidden md:block">
                    {t('cellar')}
                  </span>
                )}
              </li>
              {/* Large Terrace */}
              <li>
                {item.terrace_area >= 10 && (
                  <span className="bg-green-600/10 text-green-600 text-sm px-2.5 py-0.75 h-6 hidden md:block">
                    {t('large_terrace')}
                  </span>
                )}
              </li>
            </li>
          </ul>
        </div>
      </div>
    </Link>
  );
}
