import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as Link2 } from 'react-router-dom';
import withRouter from '../component/withrouter';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import { formatPriceInfo } from '../helpers/Priceformat';

import { getProperty, getPropertyImages } from '../api/immotoolbox.js';

import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import {
  BsTelephone,
  LiaCompressArrowsAltSolid,
  LiaBathSolid,
  AiOutlineCamera,
  LuBedSingle
} from '../assets/icons/vander';
import WhatsAppButton from '../component/WhatsApp';

function PropertyDetail(props) {
  const { t, i18n } = useTranslation();
  const [photoIndex, setActiveIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [property, setProperty] = useState({});
  const [images, setImages] = useState([]);
  const [largeImages, setLargeImages] = useState([]);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const id = props.params.id;

    const fetchData = async () => {
      try {
        const propertyData = await getProperty(id);
        setProperty(propertyData);
        let imageData = await getPropertyImages(id);
        if (typeof imageData === 'object') {
          imageData = Object.values(imageData);
        }
        const newImages = imageData.map((img) => img.medium);
        setImages(newImages);
        const newLargeImages = imageData.map((img) => img.large);
        setLargeImages(newLargeImages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [props.params.id]);

  useEffect(() => {
    if (property && property.texts) {
      let newDescription = '';
      if (
        i18n.language in property.texts &&
        property.texts[i18n.language].description
      ) {
        newDescription = property.texts[i18n.language].description;
      } else {
        newDescription = property.texts['fr'].description;
      }
      setDescription(newDescription);
    }
  }, [property, i18n.language]);

  const handleClick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };

  return (
    <>
      <Navbar />
      {/* <!-- Hero Start --> */}
      <section className="relative md:pb-6 pb-4 mt-20">
        <div className="container-fluid pb-4">
          <div className={`md:flex mt-4 ${images.length <=2 ? "justify-center" : "grid grid-cols-2" }`}>
            {images.slice(0, 4).map((image, index) => (
              <div className="p-1" key={index}>
                <div className="group relative overflow-hidden">
                  <img src={image} alt={`property img ${index}`} />
                  <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                  <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                    <Link2
                      to="#"
                      onClick={() => handleClick(index)}
                      className="btn btn-icon bg-[#000] hover:shadow-lg text-white rounded-full lightbox"
                    >
                      <AiOutlineCamera className="text-lg" />
                    </Link2>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container md:mt-18 mt-14">
          <div className="md:flex">
            <div className="lg:w-2/3 md:w-1/2 md:p-4 mx-3 md:border-r-2">
              <h4 className="text-2xl font-medium">{property?.reference}</h4>
              <h5 className="text-xl font-medium mt-4">
                {property?.district_name}
              </h5>
              {property?.type_property === 'Parking' ? (
                ''
              ) : (
                <ul className="py-6 flex items-center list-none">
                  <li className="flex items-center lg:me-6 me-4">
                    <LiaCompressArrowsAltSolid className="lg:text-3xl text-2xl me-2 text-[#000] dark:text-white" />
                    <span className="lg:text-xl">{property?.total_area}m2</span>
                  </li>

                  <li className="flex items-center lg:me-6 me-4">
                    <LuBedSingle className="lg:text-3xl text-2xl me-2 text-[#000] dark:text-white" />
                    <span className="lg:text-xl">
                      {property?.num_bedrooms <= 0
                        ? t('studio')
                        : property?.num_bedrooms == 1
                        ? property?.num_bedrooms + ' ' + t('beds').slice(0, -1)
                        : property?.num_bedrooms + ' ' + t('beds')}
                    </span>
                  </li>

                  <li className="flex items-center">
                    <LiaBathSolid className="lg:text-3xl text-2xl me-2 text-[#000] dark:text-white" />
                    <span className="lg:text-xl">
                      {property?.num_bathrooms} {t('bathrooms')}
                    </span>
                  </li>
                </ul>
              )}

              {
                <div
                  className="py-10  bg-slate-50 dark:bg-slate-900 shadow dark:shadow-gray-800 px-2"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              }

              <div className="w-full leading-[0] border-0 mt-6">
                <iframe
                  title="New iframe"
                  src={property?.mapUrl}
                  style={{ border: '0' }}
                  className="w-full h-[500px]"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <div className="lg:w-5/12 md:w-5/12 md:p-4 px-3 mt-8 md:mt-0">
              <div className="sticky top-20">
                <div className=" bg-slate-50 dark:bg-slate-900 shadow dark:shadow-gray-800 py-4">
                  <div className="p-6">
                    <h5 className="text-2xl font-medium">{t('price')} :</h5>

                    <div className="flex justify-between items-center mt-4 pb-8">
                      <span className="text-xl font-medium">
                        {formatPriceInfo(property)}
                      </span>

                      <span className="bg-green-600/10 text-green-600 text-sm px-2.5 py-0.75 h-6">
                        {property?.type_transaction_code === 'rental'
                          ? t('for-rent')
                          : t('for-sale')}
                      </span>
                    </div>

                    <span className='mt-4 text-gray-500 dark:text-gray-400 text-center'>
                      {t('moreInfoAvailable')}
                      {t('justACallAway')}
                    </span>

                    {/* <ul className="list-none mt-4">
                      <li className="flex justify-between items-center">
                        <span className="text-gray-500 dark:text-gray-400 text-sm">
                          {t('days_on_findr')}
                        </span>
                        <span className="font-medium text-sm">
                          {
                            //Date created date now comparison
                            Math.floor(
                              (new Date() - new Date(property?.datePublished)) /
                                (1000 * 60 * 60 * 24)
                            ) +
                              ' ' +
                              t('days')
                          }
                        </span>
                      </li>

                      <li className="flex justify-between items-center mt-2">
                        <span className="text-gray-500 dark:text-gray-400 text-sm">
                          {t('price_per_m2')}
                        </span>
                        <span className="font-medium text-sm">
                          {property?.type_property === 'Parking'
                            ? 'Parking'
                            : property?.priceIsPrivate
                            ? 'On Demand'
                            : (
                                property?.price / property?.total_area
                              ).toFixed() + ' €'}
                        </span>
                      </li>
                    </ul> */}
                  </div>

                  <div className="flex">
                    <div className="p-1 w-full flex justify-center my-2">
                      <WhatsAppButton
                        phoneNumber={'377680865416'}
                        propertyId={property?.id}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-12 text-center">
                  <h3 className="mb-6 text-xl leading-normal font-medium text-black dark:text-white">
                    {t('have_any_questions_get_in_touch')}
                  </h3>

                  <div className="mt-6">
                    <Link2
                      to="/contact"
                      className="btn bg-transparent hover:bg-[#000] dark:hover:bg-white border border-[#000] dark:border-white text-[#000] dark:text-white hover:text-white dark:hover:text-[#000] "
                    >
                      <BsTelephone className="align-middle me-2" />{' '}
                      {t('contact_us')}
                    </Link2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isOpen && images && (
        <Lightbox
          mainSrc={largeImages[photoIndex]}
          nextSrc={largeImages[(photoIndex + 1) % largeImages.length]}
          prevSrc={largeImages[(photoIndex + images.length - 1) % largeImages.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((photoIndex + largeImages.length - 1) % largeImages.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((photoIndex + 1) % largeImages.length)
          }
        />
      )}
      <Footer />
    </>
  );
}

export default withRouter(PropertyDetail);
