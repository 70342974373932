import React, {useState, useEffect} from 'react';
import {
  BiLogoWhatsapp,
} from '../assets/icons/vander';

export default function WhatsAppButton({ phoneNumber, propertyId }) {
  const [isBanned, setIsBanned] = useState(false);
  const message = `Hello, I'm interested in the property with ID ${propertyId}. Could you please provide more information?`;
  const encodedMessage = encodeURIComponent(message);
  const formattedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Removing non-numeric characters
  const webURL = `https://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`;
  const appURL = `whatsapp://send?phone=${formattedPhoneNumber}&text=${encodedMessage}`;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const url = isMobile ? appURL : webURL;

  // If from Africa or Pakistan, ban the user

  useEffect(() => {
  try {
    fetch('https://ipapi.co/json/')
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data.country === 'PK' || data.continent_code === 'AF') {
        setIsBanned(true);
      }
    }
    );
  }
  catch(err) {
    console.log(err);
  }
  }, []);

  return (
    isBanned ? <p className="text-red-500">Sorry, we are not able to provide our services in your country</p> :
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="button btn bg-transparent border-2 border-green-500 text-green-500 hover:text-white hover:bg-green-500 dark:hover:text-[#000]  self-center"
    >
      <BiLogoWhatsapp className="align-middle me-2 text-2xl" />
      Contact via WhatsApp
    </a>
  );
}
