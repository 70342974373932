import React from 'react';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import IframeResizer from 'iframe-resizer-react';
import { useRef } from 'react';

export default function Blogs() {
  const iframeRef = useRef(null);
  const placeholderRef = useRef(null);
  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section className="relative table w-full py-32 lg:py-36 bg-[url('../../assets/images/bg/day1350.webp')] dark:bg-[url('../../assets/images/bg/night1350.webp')]  bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-[#000] opacity-80"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              Blogs & News
            </h3>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      {/* <!-- Start --> */}

      <section className="relative md:py-24 py-16">
        <div
          className="h-[70vh]"
          ref={placeholderRef}
          style={{
            display: 'block',
          }}
        ></div>
        <IframeResizer
          log
          ref={iframeRef}
          onResized={() => {
            placeholderRef.current.style.display = 'none';
          }}
          frameborder="0"
          minHeight="500px"
          src="https://a501cd822d6b425abc39aefbde761ac9.elf.site"
          style={{
            width: '90vw',
            display: 'block',
            margin: '0 auto',
          }}
        />
      </section>

      <Footer />
    </>
  );
}
